import {environment} from "../../../environments/environment";
import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {IUserInfoSearchFilter} from "../../shared/model/user-info-search-filter.model";
import {Observable} from "rxjs";
import {IUserInfo} from "../../shared/model/userInfo.model";

const BASE_URL: string = `${environment.apiUrl}/user`

@Injectable({ providedIn: 'root' })
export class UserInfoRepository {
  constructor(private http: HttpClient) {}

  public getUser(id: string): Observable<IUserInfo> {
    return this.http.get<IUserInfo>(`${BASE_URL}/${id}`);
  }

  public findUsers(searchFilter: IUserInfoSearchFilter): Observable<IUserInfo[]> {
    return this.http.post<IUserInfo[]>(`${BASE_URL}/search`, searchFilter);
  }

  public updateUser(user: IUserInfo): Observable<IUserInfo> {
    return this.http.put<IUserInfo>(`${BASE_URL}/${user.id}`, user);
  }
}
